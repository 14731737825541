import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView'
import AboutView from "@/views/AboutView"
import Rules from "@/views/Rules"

const routes = [
  {
    path: '/',
    name: 'ЗАЛ ЦИКЛОРАМА',
    component: HomeView
  },
  {
    path: '/#dodatkovi-poslugi-fotostudiyi',
    name: 'ДОДАТКОВІ ПОСЛУГИ ФОТОСТУДІЇ',
    component: HomeView
  },
  {
    path: '/rules',
    name: 'Правила',
    component: Rules
  },
  {
    path: '/about',
    name: 'Контакти',
    component: AboutView
  }
]

const router = createRouter({
  mode: 'hash',
  history: createWebHistory(), //process.env.BASE_URL),
  routes
})

export default router
