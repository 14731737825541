import {createStore} from 'vuex'
import axios from "axios";

// let main_path ='http://localhost:8000/'
let main_path ='https://www.fotka.kiev.ua/'
// let main_path ='http://sokol.pp.ua/'
export default createStore({
    state: {
        navigations: [],
        contacts: [],
        rules: [],
        addservice: {},
        articles: [],
        all: [],
        spinner: true,
        logo_hide: true,
        mob_showe: false,
    },
    getters: {},
    mutations: {
        // GetInfo: (state, infos) => {
        //     state.all = infos
        //     state.spinner = false
        // },
        GetNavi: (state, infos) => {
            state.navigations = infos
        },
        GetArtcl: (state, infos) => {
            state.articles = infos
        },
        GetAddSrv: (state, infos) => {
            state.addservice = infos[0]
        },
        GetRls: (state, infos) => {
            state.rules = infos[0]
        },
        GetCntk: (state, infos) => {
            state.contacts = infos[0]
        },

        LogoSwitcher:(state, bl)=>{
            state.logo_hide = bl
        },
        MobMnShow:(state)=>{
            state.mob_showe = !state.mob_showe
        }
    },
    actions: {
        // GetData: ({commit}) => {
        //     axios.get('http://localhost:8000/photostudio').then(response => {
        //         commit('GetInfo', response.data)
        //     })
        // },
        MMShow:({commit}) => {
            commit('MobMnShow')
        },
        GetNavigation: ({commit}) => {
            axios.get(main_path+'photostudio/nvgt/').then(response => {
                commit('GetNavi', response.data)
            })
        },
        GetArticles: ({commit}) => {
            axios.get(main_path+'photostudio/article/').then(response => {
                commit('GetArtcl', response.data)
            })
        },

        GetAdditionalService: ({commit}) => {
            axios.get(main_path+'photostudio/addserv/').then(response => {
                commit('GetAddSrv', response.data)
            })
        },
        GetRules: ({commit}) => {
            axios.get(main_path+'photostudio/rules/').then(response => {
                commit('GetRls', response.data)
            })
        },
        GetContacts: ({commit}) => {
            axios.get(main_path+'photostudio/contacts/').then(response => {
                commit('GetCntk', response.data)
            })
        }
    },
})
