<template>
  <div>
        <topmenu :data_nav="navigations"></topmenu>
        <rules ></rules>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import MenuNavigation from "@/components/MenuNavigation";
    import RulesMain from "@/components/RulesMain";
    
    export default {
        name: "AppMain",
        components: {
            topmenu: MenuNavigation,
            rules: RulesMain,
        },
        computed: mapState(["rules",]),
        mounted() {
            this.$store.dispatch('GetNavigation')
        },
    }
</script>