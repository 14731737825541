<template>
    <div class="logo">
        <svg version="1.1" id="logo-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 482.063 482.095" style="max-width: 80px; enable-background:new 0 0 482.063 482.095;" xml:space="preserve" alt="720 production">
            <polygon class="outer_line" v-bind:class="{ 'outer--hidden': !outer_show }" style="fill:none;stroke:#ffffff;stroke-width:3;stroke-miterlimit:10;" points="241.048,478.984 34.98,360.01 34.981,122.065 241.048,3.092 447.113,122.065 447.114,360.01 "></polygon>
            <path class="svg_fill" d="M332.766,205.256c0.82-20.807,17.948-37.426,38.973-37.426c21.022,0,38.152,16.619,38.973,37.426 h14.638c-0.836-28.883-24.491-52.046-53.57-52.046s-52.733,23.163-53.57,52.046H332.766z"></path>
            <path class="svg_fill" d="M410.791,259.432c-0.82,20.807-17.948,37.426-38.973,37.426c-21.022,0-38.152-16.619-38.972-37.426 h-14.639c0.837,28.883,24.492,52.045,53.57,52.045c29.08,0,52.734-23.162,53.57-52.045H410.791z"></path>
            <path style="fill:none;stroke:#F24942;stroke-miterlimit:10;" d="M284.414,352.42"></path>
            <path style="fill:#ffffff; stroke:#ffffff; stroke-miterlimit:10;" d="M240.556,153.228c-29.076,0-52.726,23.15-53.577,52.028h14.56 c0.825-20.803,17.956-37.409,38.974-37.409c21.542,0,40.1,17.483,39.008,38.983c-0.411,8.057-4.927,14.022-11.979,21.894 c-0.325,0.316-0.666,0.645-1.054,1.015c-2.916,2.782-4.991,4.404-7.874,6.826c-5.223,4.386-71.673,60.279-71.673,60.279h22.654 l65.326-53.797c0,0,3.557-3.327,5.955-5.978l0.34,0.208c2.284-2.163,5.054-5.992,7.122-9.473c1.967-3.312,5.819-12.313,5.819-20.957 C294.158,177.239,270.156,153.228,240.556,153.228z"></path>
            <path class="svg_fill" d="M108.946,153.228c-0.005,0-0.012,0-0.017,0c-29.076,0-52.725,23.151-53.576,52.029h14.559 c0.826-20.803,17.956-37.409,38.975-37.409c0.012,0,0.024,0.001,0.036,0.001v-14.62h0.02v14.637l53.57-0.018v-14.62H108.946z"></path>
            <g>
                <rect x="184.238" y="294.832" style="fill:none;" width="164.739" height="24.379"></rect>
                <path class="svg_fill" d="M191.313,311.779c-0.598,0-1.141-0.109-1.628-0.328s-0.892-0.49-1.214-0.813h-0.046 c0.008,0.154,0.015,0.371,0.023,0.65s0.012,0.586,0.012,0.914v4.637h-2.727v-16.498h2.393l0.115,1.254h0.046 c0.337-0.445,0.771-0.811,1.3-1.098c0.529-0.289,1.143-0.432,1.841-0.432c0.606,0,1.202,0.113,1.789,0.338 c0.587,0.227,1.106,0.574,1.559,1.043c0.453,0.467,0.815,1.059,1.087,1.771c0.272,0.713,0.408,1.561,0.408,2.543 c0,1.035-0.146,1.93-0.437,2.686s-0.675,1.381-1.15,1.875c-0.476,0.496-1.003,0.861-1.582,1.1 C192.523,311.66,191.927,311.779,191.313,311.779z M190.957,302.252c-0.476,0.008-0.928,0.125-1.357,0.352 c-0.43,0.225-0.81,0.531-1.139,0.914v4.971c0.115,0.131,0.253,0.26,0.414,0.391s0.341,0.25,0.541,0.357 c0.2,0.107,0.42,0.195,0.662,0.264c0.242,0.068,0.493,0.104,0.754,0.104c0.422,0,0.797-0.09,1.127-0.27 c0.33-0.182,0.604-0.436,0.823-0.766c0.218-0.33,0.385-0.723,0.5-1.18c0.115-0.455,0.172-0.965,0.172-1.523 c0-0.6-0.06-1.125-0.178-1.576c-0.119-0.453-0.288-0.83-0.506-1.129c-0.218-0.299-0.481-0.525-0.788-0.678 C191.674,302.328,191.332,302.252,190.957,302.252z"></path>
                <path class="svg_fill" d="M205.315,302.506h-0.23c-0.836,0-1.57,0.123-2.203,0.373s-1.122,0.631-1.467,1.145v7.467h-2.727 v-11.148h2.255l0.253,1.553h0.046c0.322-0.566,0.765-1.014,1.329-1.34c0.563-0.326,1.241-0.488,2.031-0.488 c0.276,0,0.514,0.016,0.713,0.045V302.506z"></path>
                <path class="svg_fill" d="M217.557,305.922c0,0.875-0.131,1.672-0.391,2.393c-0.261,0.723-0.635,1.34-1.122,1.854 s-1.076,0.91-1.766,1.191c-0.69,0.279-1.461,0.42-2.313,0.42c-0.875,0-1.659-0.141-2.353-0.42 c-0.694-0.281-1.283-0.678-1.766-1.191c-0.483-0.514-0.853-1.131-1.11-1.854c-0.257-0.721-0.385-1.518-0.385-2.393 s0.13-1.672,0.391-2.393s0.635-1.338,1.122-1.854c0.487-0.514,1.076-0.91,1.766-1.189c0.69-0.281,1.461-0.42,2.313-0.42 c0.875,0,1.659,0.139,2.353,0.42c0.694,0.279,1.283,0.676,1.766,1.189c0.483,0.516,0.853,1.133,1.11,1.854 C217.429,304.25,217.557,305.047,217.557,305.922z M214.738,305.922c0-0.59-0.069-1.111-0.207-1.564 c-0.138-0.453-0.33-0.832-0.576-1.139s-0.541-0.537-0.886-0.691c-0.345-0.152-0.721-0.23-1.127-0.23 c-0.399,0-0.769,0.078-1.11,0.23c-0.341,0.154-0.635,0.385-0.88,0.691s-0.437,0.686-0.575,1.139s-0.207,0.975-0.207,1.564 c0,0.592,0.069,1.113,0.207,1.564c0.138,0.453,0.332,0.83,0.581,1.133c0.249,0.305,0.544,0.533,0.886,0.691 c0.341,0.156,0.715,0.236,1.122,0.236c0.406,0,0.778-0.08,1.116-0.236c0.337-0.158,0.628-0.387,0.874-0.691 c0.246-0.303,0.438-0.68,0.576-1.133C214.669,307.035,214.738,306.514,214.738,305.922z"></path>
                <path class="svg_fill" d="M219.398,306.141c0-0.975,0.134-1.842,0.403-2.605c0.268-0.764,0.634-1.404,1.099-1.922 c0.464-0.518,0.995-0.904,1.594-1.162c0.598-0.258,1.219-0.385,1.864-0.385c0.299,0,0.583,0.025,0.852,0.08 c0.268,0.053,0.521,0.127,0.759,0.219c0.237,0.092,0.46,0.195,0.667,0.311c0.208,0.115,0.399,0.234,0.576,0.355h0.023 c-0.008-0.152-0.014-0.369-0.018-0.648c-0.004-0.281-0.005-0.586-0.005-0.916v-4.637h2.727v13.348c0,0.736,0.008,1.369,0.023,1.898 s0.031,1,0.046,1.414h-2.462l-0.115-1.186h-0.046c-0.161,0.193-0.355,0.381-0.581,0.564s-0.476,0.344-0.748,0.479 c-0.272,0.133-0.571,0.238-0.897,0.316c-0.326,0.076-0.681,0.115-1.064,0.115c-0.606,0-1.187-0.109-1.743-0.322 c-0.556-0.215-1.055-0.547-1.496-0.996c-0.441-0.449-0.794-1.035-1.059-1.76S219.398,307.123,219.398,306.141z M224.771,309.604 c0.269,0,0.523-0.035,0.765-0.104s0.47-0.16,0.685-0.275s0.405-0.242,0.57-0.381c0.165-0.137,0.305-0.271,0.42-0.402v-5.293 c-0.322-0.26-0.683-0.477-1.082-0.648c-0.399-0.174-0.821-0.26-1.266-0.26c-0.284,0-0.581,0.057-0.892,0.172 c-0.311,0.115-0.594,0.311-0.851,0.588c-0.257,0.275-0.472,0.66-0.645,1.15c-0.172,0.49-0.259,1.115-0.259,1.875 c0,1.121,0.217,1.994,0.65,2.623C223.3,309.277,223.935,309.596,224.771,309.604z"></path>
                <path class="svg_fill" d="M238.037,311.779c-1.12,0-2.048-0.191-2.784-0.57s-1.292-0.893-1.668-1.537 c-0.199-0.344-0.347-0.725-0.443-1.139s-0.144-0.873-0.144-1.381v-6.811h2.727v6.523c0,0.377,0.027,0.701,0.081,0.973 s0.134,0.508,0.242,0.707c0.184,0.338,0.445,0.59,0.782,0.754c0.337,0.166,0.74,0.248,1.208,0.248c0.491,0,0.911-0.092,1.26-0.277 c0.349-0.184,0.611-0.463,0.788-0.84c0.176-0.359,0.265-0.863,0.265-1.506v-6.582h2.727v6.811c0,0.906-0.157,1.67-0.472,2.291 c-0.177,0.352-0.405,0.672-0.685,0.961c-0.28,0.287-0.61,0.533-0.989,0.736c-0.38,0.203-0.81,0.359-1.289,0.471 S238.627,311.779,238.037,311.779z"></path>
                <path class="svg_fill" d="M253.983,311.25c-0.345,0.145-0.767,0.27-1.266,0.373s-1.024,0.156-1.576,0.156 c-0.806,0-1.558-0.115-2.255-0.346c-0.698-0.23-1.309-0.58-1.83-1.047c-0.521-0.469-0.932-1.061-1.23-1.777 c-0.299-0.719-0.449-1.557-0.449-2.516c0-1.072,0.173-1.994,0.518-2.76c0.345-0.768,0.792-1.391,1.341-1.871 c0.547-0.479,1.165-0.832,1.852-1.059c0.687-0.225,1.363-0.338,2.031-0.338c0.498,0,0.978,0.043,1.438,0.131 c0.461,0.09,0.879,0.209,1.254,0.363v2.221c-0.337-0.146-0.695-0.264-1.075-0.352s-0.788-0.133-1.226-0.133 s-0.855,0.068-1.254,0.203c-0.398,0.133-0.752,0.346-1.059,0.639c-0.307,0.291-0.551,0.676-0.73,1.156 c-0.18,0.479-0.271,1.057-0.271,1.73c0,0.49,0.069,0.953,0.207,1.387s0.339,0.803,0.604,1.109c0.264,0.309,0.602,0.555,1.012,0.742 c0.41,0.189,0.896,0.283,1.456,0.283c0.499,0,0.95-0.051,1.357-0.15s0.79-0.223,1.15-0.367V311.25z"></path>
                <path class="svg_fill" d="M263.154,311.479c-0.308,0.092-0.674,0.166-1.1,0.219c-0.426,0.055-0.822,0.082-1.19,0.082 c-0.937,0-1.701-0.152-2.296-0.455c-0.594-0.303-1.014-0.738-1.26-1.307c-0.176-0.406-0.264-0.959-0.264-1.656v-5.775h-2.083 v-2.244h2.083v-3.117h2.727v3.117h3.221v2.244h-3.221v5.418c0,0.43,0.064,0.748,0.195,0.955c0.23,0.354,0.686,0.529,1.369,0.529 c0.314,0,0.627-0.023,0.938-0.074s0.604-0.113,0.881-0.189V311.479z"></path>
                <path class="svg_fill" d="M281.77,305.922c0,0.875-0.131,1.672-0.392,2.393c-0.261,0.723-0.635,1.34-1.121,1.854 c-0.487,0.514-1.076,0.91-1.767,1.191c-0.69,0.279-1.461,0.42-2.313,0.42c-0.874,0-1.659-0.141-2.353-0.42 c-0.694-0.281-1.283-0.678-1.767-1.191s-0.854-1.131-1.11-1.854c-0.257-0.721-0.385-1.518-0.385-2.393s0.13-1.672,0.391-2.393 s0.635-1.338,1.122-1.854c0.487-0.514,1.075-0.91,1.766-1.189c0.69-0.281,1.462-0.42,2.313-0.42c0.875,0,1.659,0.139,2.354,0.42 c0.694,0.279,1.282,0.676,1.766,1.189c0.483,0.516,0.854,1.133,1.11,1.854S281.77,305.047,281.77,305.922z M278.95,305.922 c0-0.59-0.068-1.111-0.207-1.564c-0.138-0.453-0.33-0.832-0.575-1.139s-0.541-0.537-0.886-0.691 c-0.345-0.152-0.721-0.23-1.128-0.23c-0.398,0-0.769,0.078-1.11,0.23c-0.341,0.154-0.635,0.385-0.88,0.691 s-0.438,0.686-0.575,1.139s-0.207,0.975-0.207,1.564c0,0.592,0.069,1.113,0.207,1.564c0.138,0.453,0.332,0.83,0.581,1.133 c0.249,0.305,0.545,0.533,0.886,0.691c0.342,0.156,0.716,0.236,1.122,0.236s0.778-0.08,1.116-0.236 c0.337-0.158,0.629-0.387,0.874-0.691c0.245-0.303,0.438-0.68,0.575-1.133C278.882,307.035,278.95,306.514,278.95,305.922z"></path>
                <path class="svg_fill" d="M265.282,294.83h2.865v2.957h-2.865V294.83z M265.352,300.342h2.728v11.148h-2.728V300.342z"></path>
                <path class="svg_fill" d="M286.693,301.895h0.046c0.161-0.23,0.364-0.455,0.61-0.678c0.245-0.223,0.524-0.418,0.84-0.588 c0.314-0.168,0.661-0.305,1.041-0.408s0.784-0.154,1.214-0.154c0.406,0,0.8,0.045,1.179,0.137c0.38,0.092,0.728,0.236,1.042,0.432 s0.592,0.449,0.834,0.76c0.241,0.311,0.432,0.682,0.569,1.111c0.076,0.236,0.129,0.496,0.155,0.775 c0.027,0.281,0.04,0.604,0.04,0.973v7.236h-2.727v-6.811c0-0.299-0.012-0.557-0.034-0.771c-0.023-0.215-0.065-0.402-0.127-0.563 c-0.138-0.354-0.347-0.607-0.627-0.76c-0.28-0.154-0.619-0.23-1.019-0.23c-0.529,0-1.037,0.125-1.524,0.373 c-0.487,0.25-0.918,0.613-1.294,1.088v7.674h-2.727v-11.148h2.255L286.693,301.895z"></path>
            </g>
            <polygon class="svg_fill" points="162.503,215.72 142.16,215.72 142.16,233.903 55.372,311.477 78.027,311.477 162.404,238.473 162.513,238.473 162.513,238.379 162.513,215.72 162.513,215.711 "></polygon>
            <circle class="outer_line" v-bind:class="{ 'outer--hidden': !outer_show }" style="fill:none; stroke:#ffffff;stroke-width:3;stroke-miterlimit:10;" cx="241.047" cy="241.038" r="239.527"></circle>
        </svg>
    </div>
</template>

<script>
    export default {
        name: "MenuLogo",
        props:['st', 'outer_show'],
    }
</script>

<style scoped>
    .svg_fill{
        fill:#fff;
    }
    .outer_line{
        display: block;
    }
    .logo:hover  .outer_line{
        transform-origin: center;
        animation:rotate 5s infinite linear;
    }
    .outer_line.outer--hidden{
        display: none;
    }
     @keyframes rotate {
         0%{}
         100%{transform: rotateZ(720deg)}
     }
</style>