<template>
  <div>
        <topmenu :data_nav="navigations"></topmenu>
        <div id="main" class="conteiner">
            <articles v-for="arts in articles" :key="arts.id" :articl="arts"></articles>
            <!-- <FormaRezerva></FormaRezerva> -->
            <insta></insta>
            <addservice></addservice>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import MenuNavigation from "@/components/MenuNavigation";
    import ArticleMain from "@/components/ArticleMain";
    import AdittionaService from "@/components/AdittionaService";
    import InstaFeed from "@/components/InstaFeed";
    // import FormaRezerva from "@/components/FormaRezerva.vue";

    export default {
        name: "AppMain",
        components: {
            topmenu: MenuNavigation,
            articles: ArticleMain,
            addservice: AdittionaService,
            insta: InstaFeed,
            // FormaRezerva
        },
        computed: mapState(["navigations", "articles", "addservice", "rules",]),
        mounted() {
            this.$store.dispatch('GetNavigation')
            this.$store.dispatch('GetArticles')
        },
    }
</script>
