<template>
    <div>
        <div class="wpr">
            <div class="row">
                <div class="row content_center">
                    <div class="adress" v-html="contacts.adress"></div>
                </div>
                <div class="row">
                    <div v-for="ctn in contacts.contacts" :key="ctn.id" class="footer_manager">
                        <a :href="'tel:+'+ctn.phone">+{{ctn.phone}}</a> {{ctn.tittle}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    export default {
        name: "ContactsMain",
        components:{
            
        },
        computed: mapState(["contacts"]),
        mounted(){
            this.$store.dispatch('GetContacts')
        }
    }
</script>

<style scoped>

    .wpr{
        border-top: 1px solid #aaa;
        color: #fff;
        padding-top: 20px;
        padding-bottom: 50px;
        background-color: #b64e45;
    }
    .row{
        display: flex;
        flex-direction: row;
        flex: 1;
    }
    .footer_manager{
        padding: 2px 10px;
    }
    .content_center{
        justify-content: center;
    }
/*     
    .slider{
        width: 100%;
        overflow: hidden;
    }
    .slider img{
        width: 100%;
    }
    .adress{
        margin: 10px 0 30px 0;
    }


    .forma{
        /*display: flex;* /
        justify-content: space-between;
        align-items: center;
        border: 1px dotted #aaa;
        padding: 50px;
        background-color: #fafafa;
        display: none;
    }
    @media screen and (max-width: 768px){
        .container{
            flex-direction: column;
        }

    } */
</style>