<template>
    <div>
        Майбутьня форма замовлення
       <div>
           <input v-model="fio" placeholder="Назовіть себе">
       </div>
       <div>
           <input v-model="phone" placeholder="Ваш контактний номер">
       </div>
       <div>
           <select v-model='selectedServices'>
               <option v-for='serv in services' :key="serv.id">
                   {{serv.name}}
               </option>
           </select>
       </div>
       <button v-on:click="submitForm([fio, phone, selectedServices])">Submit</button>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "FormaRezerva",
        data() {
            return {
                fio:'',
                phone: '',
                services:[
                    {id:1, name:'Зал Циклорама'},
                    // {id:2, name:'Зал предметка'}
                ],
                selectedServices: '',

            }
        },
        computed: mapState(["articles"]),
        methods:{
            submitForm:(a)=>{

                console.log('Forma', a)
            },
        },
    }
</script>


<style scoped>

</style>