<template>
    <div class="desccontainer">
        <div class="description" v-html="data_text">
        </div>
        <div class="price"> Ціна: {{price}} грн/година</div>
    </div>
</template>

<script>
    export default {
        name: "ArticleDescription",
        props:['data_text', 'price']
    }
</script>

<style scoped>
    .desccontainer{
        margin-top: 50px;
        color: white;
        width: 40%;
    }
    .price{

        color: #fff;
        padding: 10px 5px;
        margin: 10px 0;
        font-weight: 200;
        font-size: 36px;
    }
    @media screen and (max-width: 768px){
        .price{
            font-size: 20px;
        }
        .description{
            font-size: 14px;
        }
        .desccontainer{
            width: 100%;
        }

    }

</style>