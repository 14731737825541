<template>
    <div class="container">
        <div class="bar1" v-bind:class="{ 'change': mob_showe }"></div>
        <div class="bar2" v-bind:class="{ 'change': mob_showe }"></div>
        <div class="bar3" v-bind:class="{ 'change': mob_showe }"></div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    export default {
        name: "MenuMobButton",
        computed: {
            ...mapState([ "mob_showe"]),
        },
    }
</script>

<style scoped>
    .container {
        display: inline-block;
        cursor: pointer;
    }

    .bar1, .bar2, .bar3 {
        width: 35px;
        height: 5px;
        background-color: #fff;
        margin: 6px 0;
        transition: 0.4s;
    }

    .bar1.change{
        transform: translate(0, 11px) rotate(-45deg);
    }

    .change.bar2{
        opacity: 0;
    }

    .change.bar3{
        transform: translate(0, -11px) rotate(45deg);
    }
</style>