<template>
    <div>
        <router-link :to="btnlink" @click="scrollTo(btnlink)">{{ btntext }}</router-link>
        <!-- <a :href="'#'+btnlink" :title="btntext" v-on:click="animatescrol">{{btntext}}</a> -->
    </div>
</template>

<script>
    export default {
        name: "MenuButton",
        props: ['btntext', 'btnlink'],
        methods:{
            // animatescrol(elm) {
            //     elm.preventDefault()
            //     let scrol_elem = 0
            //     let full_link = elm.target.href
            //     let anchor_link = full_link.slice(full_link.indexOf('#')+1)
            //     if (anchor_link != 'top'){
            //         scrol_elem = document.getElementById(anchor_link).offsetTop - 50
            //         if (scrol_elem < 100) {
            //             scrol_elem = 0
            //         }
            //     }
            //     window.scrollTo({
            //         top: scrol_elem,
            //         behavior: 'smooth'
            //     })
            // },
            isAnchorLink(path) {
               return path.startsWith('/#');
            },
            scrollTo(path) {
                if (this.isAnchorLink(path)) {
                    const anchor = path.substring(1);
                    const element = document.getElementById(anchor.substring(1));
                    if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                    }
                } else {
                    window.scrollTo(0, 0); // Прокрутка до верху сторінки
                }
            }
        },
    }
</script>

<style scoped>
    a{
        text-decoration: none;
        text-transform: uppercase;
        color: #fff;
    }
    a:hover{
        color: #ccc;
    }
</style>