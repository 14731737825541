<template>
    <div class="conteiner">
        <div>
            <div class="wrapper">
                <titles :article_anchor="addservice.slug" :article_title="addservice.tittle"></titles>
            </div>
            <div class="blcok">
                <adserv v-for="srv in addservice.serveces" :key="srv.id"
                        :data_tittle="srv.tittle"
                        :data_price="srv.price"
                        :data_counter="srv.counter"
                ></adserv>
            </div>
            <div class="wrapper">
            <sup v-html="addservice.addrules"></sup>
            </div>
        </div>
    </div>
</template>

<script>
    // import {mapState} from "vuex";
    import AdittionaServiceItem from "@/components/AdittionaServiceItem";
    import ArticleTitle from "@/components/ArticleTitle";
    import {mapState} from "vuex";
    export default {
        name: "AdittionaService",
        components:{
            adserv:AdittionaServiceItem,
            titles:ArticleTitle,
        },
        computed: mapState(["addservice",]),
        mounted(){
            this.$store.dispatch('GetAdditionalService')
        }

    }
</script>

<style scoped>
    .blcok{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    sup{
        color: #BC2F2C;
        font-style: italic;
    }
</style>