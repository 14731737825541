<template>
    <div>
        <div class="fixed" v-bind:class="{ 'notop': !logo_hide }">
            <div class="menu_container" v-bind:class="{ 'notop': !logo_hide }">
                <div class="logo_block">
                    <a href="/#top">
                        <logo :outer_show="logo_hide"/>
                    </a>
                </div>
                <buttion v-for="route in routes" :key="route.name" :btntext="route.name"
                             :btnlink="route.path" :class="['buttion', 'main_nav']">
                </buttion> 
                <mobbtn class="mob" v-on:click="m_change()"></mobbtn>
                <div class="btn_container"  v-if="mob_showe">
                    <buttion v-for="route in routes" :key="route.name" :btntext="route.name"
                             :btnlink="route.path" class="buttion" v-on:click="m_change()">
                    </buttion>    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import MenuButton from "@/components/MenuButton";
    import MenuLogo from "@/components/MenuLogo";
    import MenuMobButton from "@/components/MenuMobButton";

    export default {
        name: "MenuNavigation",
        components: {
            buttion: MenuButton,
            logo: MenuLogo,
            mobbtn: MenuMobButton,
        },
        props: ['data_nav',],
        computed: {
            ...mapState(["logo_hide", "mob_showe"]),
            // filteredRoutes() {
            //     return this.routes.filter(route => route.path !== this.$route.path);
            // },
        },
        data() {
            return {
            routes: [],
            mobshow: false
            }
        },
        mounted() {
            this.$store.dispatch('GetNavigation')
            this.routes = this.$router.options.routes;
            this.scrollTo(this.$route.fullPath);
            document.addEventListener('scroll', () => {
                if (window.pageYOffset > 10) {
                    this.$store.commit('LogoSwitcher', false)
                } else {
                    this.$store.commit('LogoSwitcher', true)
                }
            })
            
            
        },
        methods: {
            m_change() {
                this.$store.dispatch('MMShow')
                this.mobshow = !this.mob_showe
            },
            isAnchorLink(path) {
               return path.startsWith('/#');
            },
            scrollTo(path) {
                console.log('start scroll to')
                if (this.isAnchorLink(path)) {
                    const anchor = path.substring(1);
                    console.log('anchor', anchor)
                    const element = document.getElementById(anchor.substring(1));
                    if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                    }
                } else {
                    console.log('scroll 00')
                    window.scrollTo(0, 0); // Прокрутка до верху сторінки
                }
            }
        },
    }

</script>

<style scoped>
    .menu_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 100px;
        background: #b64e45;
        border-bottom: 1px solid #fc968d;
    }
    .fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }
    a{
        text-decoration: none;
        text-transform: uppercase;
        color: #fff;
    
    }
    .fixed.notop, .menu_container.notop {
        animation: smalest_heights 0.5s forwards;
        /*transition: height ;*/
    }

    @keyframes smalest_heights {
        0% {
            top: 0;
            height: 80px;
        }
        100% {
            top: 0;
            height: 40px;
            /*background: rgba(255,255,255,1);*/
            /*border-bottom: 1px #BC2F2C solid;*/
        }
    }

    .btn_container {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
    }

    .buttion {
        padding: 5px 10px;
    }

    .logo_block {
        min-width: 80px;
        margin: 5px 10px 5px 122px;
    }

    .mob {
        display: none;
    }

    @media screen and (max-width: 768px) {
        .mob {
            display: block;
            margin-right: 10px;
        }
        .main_nav{
            display: none;
        }
        div#top {
            height: 80px;
        }

        .logo {
            margin: 5px 5px;
        }
        .logo_block {
                margin: 5px 10px 5px 10px;
            }
        .btn_container {
            flex-direction: column;
            position: absolute;
            top: 100px;
            background-color: #b64e45;
            /* display: none; */
            min-height: 50vh;
        }

        .btn_container.mobshow {
            display: block;
        }

        .buttion {
            text-align: center;
            margin: 30px 0;
        }

        .menu_container {
            justify-content: space-between;
        }
    }
</style>