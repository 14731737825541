<template>
    <div>
        <div class="gallerys">
            <silent-box :gallery="images"></silent-box>
        </div>
    </div>
</template>

<script>

    export default {
        name: "ArticleGallery",
        props: ['images',],
    }
</script>

<style>
    .silentbox-item {
        margin: 1px;
        width: calc(25% - 11px);
        aspect-ratio: 1;
    }

    .silentbox-item img {
        width: 100%;
        height: 100%;
    }
    .silentbox-item {
        margin: 1px;
        min-width: 20vw;
        aspect-ratio: 1;
    }

    .gallerys {
        overflow-x: scroll;
        position: relative;
    }

    .gallerys > div {
        width: 100%;
        margin: 10px 0;
        display: flex;
        flex-direction: row;

    }

    .gallerys > div > * {
        box-sizing: border-box;
    }

    @media screen and (max-width: 768px){
        .gallerys {
            overflow: scroll;
        }
        .silentbox-item {
            min-width: 65vw;

        }
    }
</style>