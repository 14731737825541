<template>
    <div :id="article_anchor">
        <h3>{{article_title}}</h3>
    </div>
</template>

<script>
    export default {
        name: "ArticleTitle",
        props: ['article_title', "article_anchor", "text_color"]

    }
</script>

<style scoped>
    h3 {
        text-transform: uppercase;
        font-size: 48px;
        font-weight: 100;
        text-align: left;
        margin: 0px 0 10px 0;
    }

    @media screen and (max-width: 768px) {
        h3 {
        font-size: 24px;
            text-align: center;
        /*border-bottom: #BC2F2C solid 1px;*/
    }
        h3#zal-ciklorama {
            margin-top: 20px;
        }
    }

</style>