<template>
    <div class="conteiner">
        <div class="wrapper">
            <titles :article_anchor="contacts.slug" :article_title="contacts.tittle"></titles>
            <div class="adress" v-html="contacts.adress"></div>
            <sup class="description" v-html="contacts.description"></sup>
        </div>
        <div class="slider">
            <img :src="contacts.slider">
        </div>
        <div v-html="contacts.maps_code" class="maps"></div>
        <div class="wrapper">
            <titles article_anchor="call" article_title="Контакти"></titles>
            <div class="row">
                <div class="row">
                <contacts v-for="ctn in contacts.contacts" :key="ctn.id" :tittle="ctn.tittle" :phone="ctn.phone" :viber="ctn.viber" :telegram="ctn.telegram" :site="ctn.site" :insta="ctn.insta"></contacts>
                </div>
                <div class="forma">
                    <rezervform></rezervform>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ArticleTitle from "@/components/ArticleTitle";
    import ContactsUser from "@/components/ContactsUser";
    import FormaRezerva from "@/components/FormaRezerva";
    import {mapState} from "vuex";
    export default {
        name: "ContactsMain",
        components:{
            titles: ArticleTitle,
            contacts: ContactsUser,
            rezervform: FormaRezerva,
        },
        computed: mapState(["contacts"]),
        mounted(){
            console.log('CONTACTS')
            this.$store.dispatch('GetContacts')
        }
    }
</script>

<style scoped>
    .slider{
        width: 100%;
        overflow: hidden;
    }
    .slider img{
        width: 100%;
    }
    .adress{
        margin: 10px 0 30px 0;
    }
    .row{
        display: flex;
        flex-direction: row;
        /*margin-bottom: 100px;*/
        /*justify-content: center;*/
    }

    .forma{
        /*display: flex;*/
        justify-content: space-between;
        align-items: center;
        border: 1px dotted #aaa;
        padding: 50px;
        background-color: #fafafa;
        display: none;
    }
    @media screen and (max-width: 768px){
        .container{
            flex-direction: column;
        }

    }
</style>