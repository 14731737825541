<template>
    <div class="conteiner red_bgr">
        <div class="wrapper">
            <tittle class="white" article_title="Наш Інстаграм" article_anchor="instagram">instagram</tittle>
        </div>
        <InstagramFeed :count="12"
                       accessToken="IGQWRQUU1vRVZA4elJzOG9vWFRwaWJWMTFFOWY5bFRDblVwNl80V2NZAUTlIbXhSVlp0OTV0M2N1aHljeWFEYU5yNldmWUpnZAk9rblAxUjRIZAGdVMWIyeU5lbUdqTkFMRElFT19hR0otNEFTVUtjSVFFcFlhcWozSFkZD"
                       :pagination="true"
                       :caption="true"></InstagramFeed>
    </div>
</template>

<script>
    import ArticleTitle from "@/components/ArticleTitle";

    export default {
        name: "InstaFeed",
        components: {
            tittle: ArticleTitle,
        }
    }
</script>

<style>
    .instagram-wrapper {
        max-width: 100%;
        margin: 0 auto;
        padding: 0;
    }

    .instagram-wrapper > div {
        display: flex;
        justify-content: space-between;
        /*margin: 0 50px 0 122px;*/
    }
    .instagram-wrapper > div:last-child{
        margin: 0 10px 0 10px;
    }

    .instagram-gallery {
        display: flex;
        flex-wrap: wrap;
        margin: -1rem, -1rem;
        padding-bottom: 3rem;
    }

    .instagram-gallery-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .instagram-gallery-item {
        position: relative;
        flex: 1 0 30%;
        margin: 5px 0;
        color: #fff;
        cursor: pointer;
    }

    .instagram-gallery-item:nth-child(2) {
        margin: 5px 10px;
    }

    .instagram-gallery-item:nth-child(3n+2) {
        margin: 5px 10px;
    }

    @media screen and (min-width: 768px) {
        .instagram-gallery {
            min-height: 25vw;
        }

        .instagram-gallery-item {
            position: relative;
            flex: 1 0 15%;
            margin: 5px;
            color: #fff;
            cursor: pointer;
        }
        .instagram-wrapper > div:last-child{
            margin: 0 50px 0 122px;
            justify-content: flex-end;
        }
        .instagram-gallery-item:nth-child(2) {
            margin: 5px;
        }

        .instagram-gallery-item:nth-child(3n+2) {
            margin: 5px;
        }

        .instagram-gallery-item:nth-child(1), .instagram-gallery-item:nth-child(7) {
            margin-left: 0;
        }

        .instagram-gallery-item:nth-child(6n+6) {
            margin-right: 0;
        }
    }
</style>