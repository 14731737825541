<template>
  <div class="about">
    <topmenu :data_nav="navigations"></topmenu>
    <contacts></contacts>
  </div>
</template>
<script>
    // import {mapState} from "vuex";
    import ContactsMain from "@/components/ContactsMain";
    import MenuNavigation from "@/components/MenuNavigation.vue";

    export default {
        name: "AppMain",
        components: {
            contacts: ContactsMain,
            topmenu: MenuNavigation,
        },
    }

</script>
<style scoped>
.conteiner{
  padding-top: 50px;
}
</style>