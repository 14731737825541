<template>
    <div class="conteiner">
        <div class="ruls_block">
            <div class="wrapper">
                <titles :article_anchor="rules.slug" :article_title="rules.tittle"></titles>
                <div class="description" v-html="rules.description"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import ArticleTitle from "@/components/ArticleTitle";
    import {mapState} from "vuex";

    export default {
        name: "RulesMain",
        components: {
            titles: ArticleTitle,
        },
        computed: mapState(["rules",]),
        mounted() {
            this.$store.dispatch('GetRules')
        },
    }
</script>

<style>
    .ruls_block {
        border: 1px solid #b64e45;
        padding-top: 50px;
    }

    .description {
        text-align: left;

    }

    ol, ul {
        padding-left: 1.6rem;
    }
    li::marker{
        font-weight: 900;
    }
    .description li {
        line-height: 1.3;
        margin: 30px 0;

    }

    @media screen and (max-width: 768px) {
        .ruls_block {
            border: none;
        }

    }
</style>