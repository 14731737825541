import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSilentbox from 'vue-silentbox'
import 'vue-silentbox/dist/style.css'
import InstagramFeed from "vue3-instagram-feed";
// import "vue3-instagram-feed/dist/style.css";


const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(VueSilentbox)
  .use(InstagramFeed)
  .mount('#app');

// createApp(App).use(store).use(router).use(VueSilentbox).mount('#app')
// createApp(App).use(store).use(VueSilentbox).use(InstagramFeed).mount('#app')
