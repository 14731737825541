<template>
    <div class="serv_row">
        <div class="title">{{data_tittle}}</div>
        <div>{{data_price}} грн/{{data_counter}}</div>
    </div>
</template>

<script>
    export default {
        name: "AdittionaServiceItem",
        props: ["data_tittle", "data_price", "data_counter"],
    }
</script>

<style scoped>
    .serv_row{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        min-height: 100px;
        min-width: 200px;
        width: calc(25% - 18px);
        height: calc(10vw - 10px);
        padding: 5px;
        margin: 2px;
        border: solid 1px #ccc;
        text-align: center;

    }
    .serv_row:only-child{
        padding: 20px 0;
    }
    .title{
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 400;
    }
    @media screen and (max-width: 768px){
        .serv_row{
            width: 100%;
            height: fit-content;
            min-height: 50px;
            margin: 10px 0;
            border: 0;
            text-align: center;
        }
        .title{
            font-size: 14px;

            border-bottom: dotted 1px #ccc;
        }
    }
</style>