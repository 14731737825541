<template>
    <!--    <nav>-->
    <!--        <router-link to="/"> Home </router-link>-->
    <!--        <router-link to="/dodatkovi-poslugi-fotostudiyi"> Add Service </router-link>-->
    <!--    </nav>-->
    <!--    <router-view></router-view>-->
    <div>
        <topmenu :data_nav="navigations"></topmenu>

        <div id="main" class="conteiner">
            <div id="app">
                <!-- <router-link to="/">Домой</router-link> |
                <router-link to="/about">О нас</router-link> -->
                <router-view />
            </div>
        </div>
        <!-- <div id="main" class="conteiner">
            <articles v-for="arts in articles" :key="arts.id" :articl="arts"></articles>
            <insta></insta>
            <addservice></addservice>
            <rules class="red_bgr white"></rules>
            <contacts></contacts>
        </div> -->
        <Footer></Footer>
    </div>
</template>
<script>

    // import {mapState} from "vuex";
    // import MenuNavigation from "@/components/MenuNavigation";
    // import ArticleMain from "@/components/ArticleMain";
    // import AdittionaService from "@/components/AdittionaService";
    // import RulesMain from "@/components/RulesMain";
    // import ContactsMain from "@/components/ContactsMain";
    // import InstaFeed from "@/components/InstaFeed";
    import Footer from "@/components/Footer.vue";

    export default {
        name: "AppMain",
        components: {
    //         topmenu: MenuNavigation,
    //         articles: ArticleMain,
    //         addservice: AdittionaService,
    //         rules: RulesMain,
    //         contacts: ContactsMain,
    //         insta: InstaFeed,
            Footer,
        },
    //     computed: mapState(["navigations", "articles", "addservice", "rules",]),
    //     mounted() {
    //         this.$store.dispatch('GetNavigation')
    //         this.$store.dispatch('GetArticles')
    //     },
    }

</script>
<style scoped>
    .conteiner{
        border-left: 1px solid #b64e45;
        border-right: 1px solid #b64e45;
    }
</style>
<style>
    body {
        margin: 0;
    }
    .wrapper{
        margin: 0 50px 0 122px;
    }
    .conteiner, .menu_container {
        width: min(100%, 1440px);
        margin-inline: auto;
    }
    .conteiner{
        margin: 100px auto 0 auto;
        padding: 0 0 20px 0;
    }
    /* .conteiner:first-child{
        margin: 100px auto 0 auto;
    } */


    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /*text-align: center;*/
        color: #2c3e50;
    }

    /*.conteiner:nth-child(odd) {*/
    /*    background-color: #ccc;*/

    /*}*/

    nav {
        padding: 30px;
    }

    nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    nav a.router-link-exact-active {
        color: #42b983;
    }

    div#main{
        margin-top: 0;
    }
    .innet-tittle {
        font-size: 36px;
        font-weight: 100;
        text-align: center;

    }
    .white{
        color: white;
    }


    .red_bgr {
        background: #b64e45;
        padding: 30px 0;
    }

    @media screen and (max-width: 768px) {
        .wrapper{
            margin: 0 10px 0 10px;
        }


    }

</style>
