<template>
    <div>
        <div class="conteiner">
            <div class="article_cover" :style="backgroundImageInlineStyle">
                <div class="black">
                    <div class="wrapper">
                        <articltitle class="white" :article_title="articl.tittle" :article_anchor="articl.slug">
                            Заголовок
                        </articltitle>
                        <articdescription :data_text="articl.description" :price="articl.price"></articdescription>
                    </div>
                </div>
                </div>
                <gallery class="gll" v-if="articl.gallery.length >0" :images="articl.gallery"/>
                <div v-html="articl.calendar"></div>
        </div>
    </div>
</template>

<script>
    import ArticleGallery from "@/components/ArticleGallery";
    import ArticleTitle from "@/components/ArticleTitle";
    import ArticleDescription from "@/components/ArticleDescription";

    export default {
        name: "ArticleMain",
        components: {
            articltitle: ArticleTitle,
            articdescription: ArticleDescription,
            gallery: ArticleGallery,
        },
        props: ['articl',],
        computed: {
            backgroundImageInlineStyle() {
                return `background-image: url("${this.articl.slider}");`;
            },
        },
    }
</script>

<style scoped>
    .black{
        background: rgba(0,0,0,0.7);
        height: 80vh;
        padding-top: 40px;
    }
    .art_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .conteiner {
        width: 100%;
        min-height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-top: 100px;
    }

    .article_cover {
        min-height: 80vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-blend-mode: darken;
    }

    img {
        height: 400px !important;
        width: 400px !important;
    }

    .gll {
        width: 100%;
        overflow: hidden;
        /*position: absolute;*/
        margin-bottom: 50px;
    }

    @media screen and (max-width: 768px) {
        .price {
            background-color: #BC2F2C;
            color: #fff;
        }

    }


</style>