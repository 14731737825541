<template>
    <div class="containers">
        <div id="call" class="tittle">{{tittle}}</div>
        <div class="contacts_data">
            <div>Телефон:</div><div><a :href="'tel:+'+phone">+{{phone}}</a></div>
            <div v-if="viber">Вайбер:</div><div v-if="viber"><a :href="'viber://chat/?number=+'+viber">+{{viber}}</a></div>
            <div v-if="telegram">Телеграм:</div><div v-if="telegram"><a :href="'https://t.me/+'+telegram">+{{telegram}}</a></div>
            <div v-if="site">Сайт:</div><div v-if="site"><a :href="site">{{site}}</a></div>
            <div v-if="insta">Інстаграм:</div><div v-if="insta"><a :href="insta">{{insta}}</a></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactsUser",
        props:["tittle", "phone", "viber", "telegram", "site", "insta",],

    }
</script>

<style scoped>
    a{
        /*color: white;*/
        text-decoration: none;
    }
    .containers{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 5px;
        margin: 5px 10px;
        flex: 1 20 200px;
    }
    .containers > div {
        margin: 10px 0;

    }
    .tittle{
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 10px;
    }
    .contacts_data{
        display: flex;
        flex-wrap: wrap;
    }
    .contacts_data > div{
        flex: 1 50 35%;
        margin: 10px 0;
    }
</style>